import { Map, fromJS } from "immutable";

import { getOrganizationDescriptionId } from "Libs/utils";
import { ENVIRONMENT_ID_FIELD } from "Constants/constants";
import logger from "Libs/logger";

import localForage from "localforage";

// const LOAD_ENVIRONMENTS_SKIP = "app/environments/load_skip";
const LOAD_ENVIRONMENTS_START = "app/environments/load_start";
const LOAD_ENVIRONMENTS_SUCCESS = "app/environments/load_success";
const LOAD_ENVIRONMENTS_FAILURE = "app/environments/load_failure";

const LOAD_ENVIRONMENT_START = "app/environment/load_start";
const LOAD_ENVIRONMENT_SKIP = "app/environment/load_skip";
const LOAD_ENVIRONMENT_SUCCESS = "app/environment/load_success";
const LOAD_ENVIRONMENT_FROM_EVENT_SUCCESS =
  "app/environment/load_from_event_success";
const LOAD_ENVIRONMENT_FAILURE = "app/environment/load_failure";

const LOAD_LAST_ENVIRONMENTS_START = "app/last_environment/load_start";
const LOAD_LAST_ENVIRONMENTS_SUCCESS = "app/last_environment/load_success";
const LOAD_LAST_ENVIRONMENTS_FAILURE = "app/last_environment/load_failure";

const UPDATE_ENVIRONMENT_START = "app/environment/update_start";
const UPDATE_ENVIRONMENT_SUCCESS = "app/environment/update_success";
const UPDATE_ENVIRONMENT_FAILURE = "app/environment/update_failure";

const TOGGLE_ACTIVATION_ENVIRONMENT_START =
  "app/environment/toggle_activation_start";
const TOGGLE_ACTIVATION_ENVIRONMENT_SUCCESS =
  "app/environment/toggle_activation_success";
const TOGGLE_ACTIVATION_ENVIRONMENT_FAILURE =
  "app/environment/toggle_activation_failure";

const TOGGLE_SMTP_START = "app/environment/toggle_smtp_start";
const TOGGLE_SMTP_SUCCESS = "app/environment/toggle_smtp_success";
const TOGGLE_SMTP_FAILURE = "app/environment/toggle_smtp_failure";

const INITIALIZE_START = "app/environment/initialize_start";
const INITIALIZE_SUCCESS = "app/environment/initialize_success";
const INITIALIZE_FAILURE = "app/environment/initialize_failure";

const TOGGLE_RESTRICT_ROBOT_START =
  "app/environment/toggle_restrict_robot_start";
const TOGGLE_RESTRICT_ROBOT_SUCCESS =
  "app/environment/toggle_restrict_robot_success";
const TOGGLE_RESTRICT_ROBOT_FAILURE =
  "app/environment/toggle_restrict_robot_failure";

const UPDATE_HTTP_ACCESS_CONTROL_START =
  "app/environment/update_http_access_control_start";
const UPDATE_HTTP_ACCESS_CONTROL_SUCCESS =
  "app/environment/update_http_access_control_success";
const UPDATE_HTTP_ACCESS_CONTROL_FAILURE =
  "app/environment/update_http_access_control_failure";

export const loadEnvironmentFromEventSuccess = (
  environment,
  organizationDescriptionId
) => {
  return {
    type: LOAD_ENVIRONMENT_FROM_EVENT_SUCCESS,
    payload: { environment },
    meta: { organizationDescriptionId }
  };
};

export const toggleSmtp = (projectDescriptionId, environmentDescriptionId) => {
  return async (dispatch, getState) => {
    dispatch({ type: TOGGLE_SMTP_START });

    try {
      const environment = getState().environment.getIn([
        "data",
        getOrganizationDescriptionId(getState, projectDescriptionId),
        projectDescriptionId,
        environmentDescriptionId
      ]);

      const result = await environment.update({
        enable_smtp: !environment.enable_smtp
      });
      const newEnvironment = await result.getEntity();

      dispatch({ type: TOGGLE_SMTP_SUCCESS, payload: newEnvironment });
    } catch (err) {
      logger(err, {
        action: "toggle_smtp",
        projectDescriptionId,
        environmentDescriptionId
      });
      dispatch({ type: TOGGLE_SMTP_FAILURE, payload: err });
    }
  };
};

export const initialize = (
  organizationDescriptionId,
  projectDescriptionId,
  profile,
  repository
) => {
  return async (dispatch, getState) => {
    dispatch({ type: INITIALIZE_START });

    try {
      const environments = getState().environment.getIn([
        "data",
        organizationDescriptionId,
        projectDescriptionId
      ]);
      const environment = environments.find(
        environment => environment.name === "master"
      );

      const result = await environment.initialize(profile, repository);
      const newEnvironment = await result.getEntity();

      dispatch({ type: INITIALIZE_SUCCESS, payload: newEnvironment });
    } catch (err) {
      logger(err);
      dispatch({ type: INITIALIZE_FAILURE, payload: err });
    }
  };
};

export const toggleRestrictRobot = (
  projectDescriptionId,
  environmentDescriptionId
) => {
  return async (dispatch, getState) => {
    dispatch({ type: TOGGLE_RESTRICT_ROBOT_START });

    try {
      const environment = getState().environment.getIn([
        "data",
        getOrganizationDescriptionId(getState, projectDescriptionId),
        projectDescriptionId,
        environmentDescriptionId
      ]);
      const result = await environment.update({
        restrict_robots: !environment.restrict_robots
      });
      const newEnvironment = await result.getEntity();

      dispatch({
        type: TOGGLE_RESTRICT_ROBOT_SUCCESS,
        payload: newEnvironment
      });
    } catch (err) {
      logger(err, {
        action: "toggle_restrict_robot",
        projectDescriptionId,
        environmentDescriptionId
      });
      dispatch({ type: TOGGLE_RESTRICT_ROBOT_FAILURE, payload: err });
    }
  };
};

export const updateHttpAccessControl = (
  projectDescriptionId,
  environmentDescriptionId,
  httpAccess
) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_HTTP_ACCESS_CONTROL_START });

    try {
      const environment = getState().environment.getIn([
        "data",
        getOrganizationDescriptionId(getState, projectDescriptionId),
        projectDescriptionId,
        environmentDescriptionId
      ]);
      const result = await environment.update({
        http_access: httpAccess
      });
      const newEnvironment = await result.getEntity();

      dispatch({
        type: UPDATE_HTTP_ACCESS_CONTROL_SUCCESS,
        payload: newEnvironment
      });
    } catch (err) {
      logger(err, {
        action: "update_http_access_control",
        projectDescriptionId,
        environmentDescriptionId,
        httpAccess
      });
      dispatch({ type: UPDATE_HTTP_ACCESS_CONTROL_FAILURE, payload: err });
    }
  };
};

export const loadEnvironments = (
  projectDescriptionId,
  organizationDescriptionId
) => {
  return async dispatch => {
    dispatch({ type: LOAD_ENVIRONMENTS_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const environments = await client.getEnvironments(projectDescriptionId);

      dispatch({
        type: LOAD_ENVIRONMENTS_SUCCESS,
        payload: {
          environments
        },
        meta: {
          projectDescriptionId,
          organizationDescriptionId
        }
      });
    } catch (err) {
      logger(err, {
        action: "environments_load",
        meta: {
          projectDescriptionId
        },
        projectDescriptionId
      });
      dispatch({ type: LOAD_ENVIRONMENTS_FAILURE, error: true, payload: err });
    }
  };
};

export const loadEnvironment = (
  environmentDescriptionId,
  projectDescriptionId,
  projectOrganizationId
) => {
  return async (dispatch, getState) => {
    const organizationDescriptionId =
      projectOrganizationId ||
      getOrganizationDescriptionId(getState, projectDescriptionId);

    let currentEnvironmentLoaded = getState().environment.getIn(
      [
        "data",
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      ],
      {}
    );
    let environmentIsLoading = getState().environment.get("loading");

    if (
      environmentIsLoading ||
      Object.keys(currentEnvironmentLoaded).length > 0
    ) {
      dispatch({ type: LOAD_ENVIRONMENT_SKIP });
      return;
    }

    dispatch({ type: LOAD_ENVIRONMENT_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const encodedEnvId = encodeURIComponent(environmentDescriptionId);

      const environment = await client.getEnvironment(
        projectDescriptionId,
        encodedEnvId
      );

      let lastVisitedEnvironments = getState().environment.get(
        "lastVisitedEnvironments"
      );

      if (!lastVisitedEnvironments) {
        lastVisitedEnvironments =
          (await localForage.getItem("lastVisitedEnvironments")) || [];
      }

      lastVisitedEnvironments = fromJS(lastVisitedEnvironments);

      const currentEnvironmentIndex = lastVisitedEnvironments.findIndex(env => {
        return env.get("id") === environment.id;
      });
      let newLastVisitedEnvironments = lastVisitedEnvironments;

      if (currentEnvironmentIndex !== -1) {
        newLastVisitedEnvironments = lastVisitedEnvironments.delete(
          currentEnvironmentIndex
        );
      }

      newLastVisitedEnvironments = newLastVisitedEnvironments.unshift(
        fromJS({ ...environment })
      );

      if (newLastVisitedEnvironments.size > 10) {
        newLastVisitedEnvironments = newLastVisitedEnvironments.pop();
      }

      dispatch({
        type: LOAD_ENVIRONMENT_SUCCESS,
        payload: {
          environment
        },
        meta: {
          organizationDescriptionId,
          lastVisitedEnvironments: newLastVisitedEnvironments
        }
      });

      localForage.setItem(
        "lastVisitedEnvironments",
        newLastVisitedEnvironments.toJS()
      );
    } catch (err) {
      let error = err;
      if (error.code === 404) {
        error.message = "environment.notfound";
      } else {
        logger(err, {
          action: "environment_load",
          meta: {
            organizationDescriptionId: getOrganizationDescriptionId(
              getState,
              projectDescriptionId
            )
          }
        });
      }
      dispatch({ type: LOAD_ENVIRONMENT_FAILURE, error: true, payload: error });
    }
  };
};

export const updateEnvironment = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId,
  environmentData
) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_ENVIRONMENT_START });

    try {
      const environment = getState().environment.getIn([
        "data",
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      ]);
      const result = await environment.update(environmentData);
      const newEnvironment = await result.getEntity();

      dispatch({
        type: UPDATE_ENVIRONMENT_SUCCESS,
        payload: {
          environment: newEnvironment
        },
        meta: {
          organizationDescriptionId
        }
      });
    } catch (err) {
      logger(err, {
        action: "environment_update",
        meta: {
          organizationDescriptionId
        },
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId,
        environmentData
      });
      dispatch({ type: UPDATE_ENVIRONMENT_FAILURE, error: true, payload: err });
    }
  };
};

export const toggleEnvironmentActivation = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId
) => {
  return async (dispatch, getState) => {
    const isAlreadyLoading = getState().environment.get(
      "toggleActivationLoading"
    );

    if (isAlreadyLoading) {
      return false;
    }

    dispatch({ type: TOGGLE_ACTIVATION_ENVIRONMENT_START });

    const environment = getState().environment.getIn([
      "data",
      organizationDescriptionId,
      projectDescriptionId,
      environmentDescriptionId
    ]);

    try {
      const isActive = await environment.isActive();

      let activity;

      if (isActive) {
        activity = await environment.deactivate();
      } else {
        activity = await environment.activate();
      }

      activity = await activity.wait();

      if (activity.result === "failure") {
        return dispatch({
          type: TOGGLE_ACTIVATION_ENVIRONMENT_FAILURE,
          error: true,
          payload: activity.log,
          meta: {
            organizationDescriptionId,
            environment
          }
        });
      }

      dispatch({
        type: TOGGLE_ACTIVATION_ENVIRONMENT_SUCCESS,
        payload: {
          environment
        },
        meta: {
          organizationDescriptionId
        }
      });
    } catch (err) {
      logger(err, {
        action: "toggleEnvironmentActivation",
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      });
      dispatch({
        type: TOGGLE_ACTIVATION_ENVIRONMENT_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const loadLastVisitedEnvironments = () => {
  return async (dispatch, getState) => {
    const environment = getState().environment;

    if (!environment) {
      return false;
    }

    let lastVisitedEnvironments = environment.get("lastVisitedEnvironments");

    if (lastVisitedEnvironments && lastVisitedEnvironments.size) {
      return false;
    }

    try {
      dispatch({ type: LOAD_LAST_ENVIRONMENTS_START });

      lastVisitedEnvironments = await localForage.getItem(
        "lastVisitedEnvironments"
      );

      dispatch({
        type: LOAD_LAST_ENVIRONMENTS_SUCCESS,
        payload: lastVisitedEnvironments
      });
    } catch (err) {
      logger(err, {
        action: "loadLastVisitedEnvironments"
      });
      dispatch({ type: LOAD_LAST_ENVIRONMENTS_FAILURE });
    }
  };
};

export default function environmentReducer(state = new Map(), action) {
  switch (action.type) {
    case UPDATE_ENVIRONMENT_START:
      return state.set("updateLoading", true);
    case LOAD_ENVIRONMENTS_START:
      return state.set("loading", true);
    case LOAD_ENVIRONMENT_START:
      return state.set("loading", true).delete("environmentLoadingError");
    case TOGGLE_ACTIVATION_ENVIRONMENT_START:
      return state.set("toggleActivationLoading", true);
    case TOGGLE_ACTIVATION_ENVIRONMENT_FAILURE:
      return state.set("toggleActivationLoading", false);
    case TOGGLE_ACTIVATION_ENVIRONMENT_SUCCESS:
      return state.set("toggleActivationLoading", false);
    case TOGGLE_RESTRICT_ROBOT_SUCCESS:
    case TOGGLE_SMTP_SUCCESS:
    case UPDATE_ENVIRONMENT_SUCCESS:
    case LOAD_ENVIRONMENT_SUCCESS:
      return state
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.payload.environment.project,
            action.payload.environment[ENVIRONMENT_ID_FIELD]
          ],
          fromJS(action.payload.environment)
        )
        .set("lastVisitedEnvironments", action.meta.lastVisitedEnvironments)
        .set("updateLoading", false)
        .set("loading", false)
        .delete("environmentLoadingError");
    case LOAD_ENVIRONMENT_FROM_EVENT_SUCCESS:
      return state.setIn(
        [
          "data",
          action.meta.organizationDescriptionId,
          action.payload.environment.project,
          action.payload.environment[ENVIRONMENT_ID_FIELD]
        ],
        fromJS(action.payload.environment)
      );
    case LOAD_ENVIRONMENTS_SUCCESS:
      return state.set("loading", false).set(
        "data",
        fromJS(
          action.payload.environments.reduce(
            (organizationsProjectsEnvironements, environment) => {
              if (
                !organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ]
              ) {
                organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ] = {};
              }
              if (
                !organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ][action.meta.projectDescriptionId]
              ) {
                organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ][action.meta.projectDescriptionId] = {};
              }
              organizationsProjectsEnvironements[
                action.meta.organizationDescriptionId
              ][action.meta.projectDescriptionId][
                environment[ENVIRONMENT_ID_FIELD]
              ] = environment;

              return organizationsProjectsEnvironements;
            },
            {}
          )
        )
      );
    case LOAD_ENVIRONMENTS_FAILURE:
      return state.set("loading", false).set("error", action.payload);
    case UPDATE_ENVIRONMENT_FAILURE:
      return state
        .set("updateLoading", false)
        .set("errors", fromJS(action.payload));
    case LOAD_ENVIRONMENT_FAILURE:
      return state
        .set("loading", false)
        .set("environmentLoadingError", fromJS(action.payload));
    default:
      return state;
  }
}
