import { fromJS, Map, List } from "immutable";
import { push as reactRouterReduxPush } from "react-router-redux";

import { loadLastVisitedProjects } from "Reducers/project";
import { loadLastVisitedEnvironments } from "Reducers/environment";
// import { loadOrganizations } from "Reducers/organization";
import { ORGANIZATION_ID_FIELD } from "Constants/constants";
import config from "Constants/api_config";

import { getEnvironmentDescriptionId } from "Libs/utils";
import parse from "Libs/urlParser";

import logger from "Libs/logger";

export const INIT_SUCCESS = "app/init_success";
const INIT_FAILURE = "app/init_failure";

const GET_ME_START = "app/get_me_start";
const GET_ME_SUCCESS = "app/get_me_success";
const GET_ME_FAILURE = "app/get_me_failure";

const OPEN_LEFT_DRAWER = "app/open_left_drawer";
const CLOSE_LEFT_DRAWER = "app/close_left_drawer";

const OPEN_RIGHT_DRAWER = "app/open_right_drawer";
const CLOSE_RIGHT_DRAWER = "app/close_right_drawer";

export const openLeftDrawer = () => ({ type: OPEN_LEFT_DRAWER });
export const closeLeftDrawer = () => ({ type: CLOSE_LEFT_DRAWER });

export const openRightDrawer = data => ({
  type: OPEN_RIGHT_DRAWER,
  payload: data
});
export const closeRightDrawer = () => ({ type: CLOSE_RIGHT_DRAWER });

export const init = () => {
  return async dispatch => {
    try {
      dispatch(getMe());
      dispatch(loadLastVisitedProjects());
      dispatch(loadLastVisitedEnvironments());

      dispatch({
        type: INIT_SUCCESS
      });
    } catch (err) {
      logger(err, {
        action: "init"
      });
      dispatch({ type: INIT_FAILURE, error: true, payload: err });
    }
  };
};

export const getMe = () => {
  return async dispatch => {
    dispatch({ type: GET_ME_START });

    try {
      // Load the current user

      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const { request } = platformLib;

      const me = await client.getAccountInfo();

      dispatch(loadLastVisitedProjects());
      dispatch(loadLastVisitedEnvironments());
      //TODO: remove comment when teams and orgs are implemented
      // dispatch(loadOrganizations());

      const meIsh = await request(`${config.api_url}/platform/me`, "GET");
      me.copy(meIsh);

      dispatch({
        type: GET_ME_SUCCESS,
        payload: {
          me: { ...me }
        }
      });
    } catch (err) {
      logger(err, {
        action: "GET_ME_START"
      });
      dispatch({ type: GET_ME_FAILURE, error: true, payload: err });
    }
  };
};
export const push = (path, args) => async (dispatch, getState) => {
  const descriptionArgs = { ...args };

  if (args.organizationId) {
    const me = getState().app.get("me", new Map());
    const organization = me
      .get("organizations", new List())
      .find(o => o.get("id") === args.organizationId);

    descriptionArgs.organizationId = organization.get(ORGANIZATION_ID_FIELD);
  }

  if (args.projectId) {
    descriptionArgs.projectId = args.projectId;
  }

  if (args.environmentId) {
    descriptionArgs.environmentId = getEnvironmentDescriptionId(
      getState,
      args.environmentId
    );
  }

  const url = parse(path, descriptionArgs);

  dispatch(reactRouterReduxPush(url));
};

export default function appReducer(state = new Map(), action) {
  switch (action.type) {
    case GET_ME_SUCCESS:
      return state.set("me", fromJS(action.payload.me));
    case OPEN_LEFT_DRAWER:
      return state.set("leftDrawerOpen", true);
    case CLOSE_LEFT_DRAWER:
      return state.set("leftDrawerOpen", false);
    case OPEN_RIGHT_DRAWER:
      return state
        .set("leftDrawerOpen", false)
        .set("rightDrawerOpen", true)
        .set("rightDrawerData", action.payload);
    case CLOSE_RIGHT_DRAWER:
      return state.set("leftDrawerOpen", true).set("rightDrawerOpen", false);
    default:
      return state;
  }
}
